import React, { useEffect, useState } from 'react';
import './App.css';
import { supabase } from './supabaseClient.js';
import logo from './logo.svg';
import { useNavigate } from 'react-router-dom';

function App() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    supabase.auth.onAuthStateChange((_event, session) => {
      if (session) {
        navigate('/thank-you');
      } else {
        setLoading(false);
      }
    });
  }, [navigate]);

  async function signInWithGithub() {
    setLoading(true);
    const { error } = await supabase.auth.signInWithOAuth({
      provider: 'github',
    });
    if (error) {
      console.error('Login error', error.message);
      setLoading(false);
    }
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      <div className="login-container">
        <img src={logo} alt="Orbiton Logo" className="logo" />
        <h1 className="title">AI Dev Trained On Your Codebase.</h1>
        <button onClick={signInWithGithub} className="login-button">
          Continue with GitHub
        </button>
      </div>
    </div>
  );
}

export default App;

// import React from 'react';
// import './App.css';
// import { supabase } from './supabaseClient.js'; // Import the supabase client
// import logo from './logo.svg'; // Import your logo image here

// function App() {
//   async function signInWithGithub() {
//     const { data, error } = await supabase.auth.signInWithOAuth({
//       provider: 'github',
//     });
//     // Add your error handling and post-sign-in logic
//   }
  
//   return (
//     <div className="App">
//       <div className="login-container">
//         <img src={logo} alt="Orbiton Logo" className="logo" />
//         <h1 className="title">AI Dev Trained On Your Codebase.</h1>
//         <button onClick={signInWithGithub} className="login-button">
//           Continue with GitHub
//         </button>
//       </div>
//     </div>
//   );
// }

// export default App;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from './supabaseClient.js'; // Import the Supabase client

const ThankYou = () => {
  const navigate = useNavigate();

  async function handleLogout() {
    // Logs the user out
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error('Error logging out:', error.message);
    } else {
      // Redirects the user to the home page after logging out
      navigate('/');
    }
  }

  return (
    <div className="thank-you-container">
      <h1>Thank You</h1>
      <p>Thank you for saving your spot...</p>
      <p>We'll reach out to you shortly to complete the setup process!</p>
      <button onClick={handleLogout} className="logout-button">
        Logout
      </button>
    </div>
  );
};

export default ThankYou;
